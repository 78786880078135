.cbdcx__section {
  width: 100%;
  height: 90%;
  position: relative;
  margin-top: 5%;
}
.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.cbdcx__container {
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
}
.cbdcx__container > h6 {
  margin: 0px;
  font-size: 15px;
  font-weight: 400;
  color: var(--secondary-black-text);
}
.cbdcx__container > h1 {
  margin: 30px 0px;
  font-size: 60px;
  font-weight: 700;
}
.cbdcx__container > p {
  margin: 0px;
  width: 80%;
  font-size: 20px;
  text-align: center;
}
.grid__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  gap: 10px;
}
.grid__content{
  width: 20%;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
}
.grid__content > h1 {
  font-size: 18px;
  margin: 10px 0px;
  text-align: center;
}
.grid__content > p {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 0px;
}
@media only screen and (max-width: 768px) {
  .cbdcx__section, .container{
    height: auto;
  }
  .cbdcx__container{
    height: auto;
  }
  .grid__container{
    display: block;
  }
  .grid__content{
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
}