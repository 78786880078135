.navbar__container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.navbar__container > nav {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar__content_container{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.logo__container{
    width: 100px;
}
.logo__container > h3 {
    font-size: 25px;
}
.menu__container{
    width: 100%;
}
.menu__container > ul{
    display: flex;
    justify-content: space-around;
    width: 80%;
}
.navbar__actions{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbar__get_started{
    width: 30%;
    height: 40px;
    border: none;
    outline: none;
    background-color: blue;
    color: white;
    padding: 10px;
    border-radius: 5px;
}
ul {
  list-style-type: none;
}
a{
    text-transform: none;
    text-decoration: none;
    color: var(--secondary-black-text);
    font-weight: 500;
}
#mobile{
    display: none;
}
@media only screen and (max-width: 768px) {
    #desktop{
        display: none;
    }
    #mobile{
        display: flex;
        justify-content: flex-end;

    }
    .drop__down_values{
        width: 100%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .drop__down_values > ul > li {
        margin: 10px 0px;
    }
    .navbar__content_container{
        width: 100%;
    }
    .logo__container{
        width: 100%;
        text-align: center;
    }
    .logo__container > h3 {
        font-size: 25px;
        margin-left: 35%;
    }
}