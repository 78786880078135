.contact__section{
    width: 100%;
    position: relative;
    background-color: var(--background-black-color);
    margin-top: 5%;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.contact__container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.contact__container > h1{
    font-size: 20px;
    font-weight: 400;
    color: white;
    width: 60%;
}
.contact__container > button{
     border: none;
     outline: none;
     padding: 10px;
     border-radius: 10px;
     background-color: blue;
     color: white;
     height: 40px;
     width: 20%;
     font-size: 16px;
     cursor: pointer;
}
@media only screen and (max-width: 768px) {
    .container{
        height: auto;
        padding-bottom: 20px;
        padding-top: 20px;
    }
    .contact__container{
        flex-direction: column;
    }
    .contact__container > h1{
        text-align: center;
        width: 100%;
        font-size: 20px !important;
    }
    .contact__container > button{
        width: 50%;
    }
}