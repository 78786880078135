.secure__section{
    width: 100%;
    height: calc(100vh - 10vh);
    position: relative;
    margin-top: 5%;
    background-image: url("../assets/backgrounds/frame2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.secure__content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.flex__content {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex__left_content{
    width: 50%;
}
.flex__left_content > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}
.flex__right_content{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 30px;
}
.flex__right_content > h1 {
    font-size: 50px;
    margin: 20px 0px;
    font-weight: 600;
}
.flex__right_content > p {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0px;
}
.flex__right_content > button {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    height: 40px;
    width: 20%;
    background-color: var(--app-who-we-are-background-color);
    margin-top: 30px;
}
@media only screen and (max-width: 768px) {
    .secure__section{
        background-image: none;
        height: auto;
    }
    .container{
        height: none;
    }
    .flex__content{
        display: block;
    }
    .flex__left_content > img {
        display: none;
    }
    .flex__right_content{
        width: 100%;
        margin-left: 0px;
    }
    .flex__content{
        width: 100%;
    }
    .flex__right_content > h1 {
        text-align: center;
        width: 100%;
    }
    .flex__right_content > p {
        text-align: center;
    }
}