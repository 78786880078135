.home__container{
    width: 100vw;
    height: 70vh;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home__content_container{
    width: 100%;
    height: 100%;
}
.home__background{
    width: 100%;
    height: 100%;
    background-image: url("../assets/backgrounds/frame_1.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}