@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root{
  --app-main-color: #fe0095;
  --app-secondary-color: #FF6954;
  --app-additional-color: #83be6e;
  --app-header-light-color: #fff;
  --app-header-light-hover-color: #fff;
  --app-header-dark-color: #000;
  --app-header-dark-hover-color: #000;
  --app-why-section-color: #48F9FE;
  --app-who-we-are-background-color: #004287;
  --background-black-color: #1f2225;
}

body {
  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
  background-color: #F7F7F7;
}

.container{
  width: 90%;
  height: 100%;
}
button{
  font-family: "Poppins", sans-serif;
}
@media only screen and (max-width: 768px) {
  h1{
    font-size: 25px !important;
  }
   p{
    font-size: 15px !important;
   }
}