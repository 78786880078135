.who__we_are_section{
    width: 100%;
    position: relative;
    background-color: var(--app-who-we-are-background-color);
    margin-top: 5%;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.who__we_are_content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.who__we_are_title{
    font-size: 60px;
    color: white;
}
.who__we_are_description{
    width: 85%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin: 0px 0px 20px 0px;
    color: white;
}
.who__we_are_stats{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 5% 0px;
}
.stat__box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30%;
    flex-grow: 1;
}
.stat__box > h1 {
    color: #00C8F6;
    font-size: 40px;
    font-weight: bold;
    margin: 0px;
}
.stat__box > p {
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin: 0px;
}
@media only screen and (max-width: 768px) {

    .container{
        height: auto;
    }
    .who__we_are_description{
        width: 100%;
    }
    .stat__box{
        flex-basis: 100%;
        margin-bottom: 30px;
    }
}