.one__app_section {
  width: 100%;
  position: relative;
  margin-top: 5%;
  height: calc(70vh - 10vh);
  /* background-image: url("../assets/backgrounds/frame2.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
}
.one__app_content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.flex__content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex__left_content{
    width: 35%;
}
.flex__left_content > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}
.flex__right_content{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 30px;
}
.flex__right_content > h1 {
    font-size: 50px;
    margin: 20px 0px;
    font-weight: 600;
    text-align: center;
}
.flex__right_content > p {
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0px;
    text-align: center;
}
.flex__right_content > button {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
    height: 40px;
    width: 20%;
    background-color: var(--app-who-we-are-background-color);
    margin-top: 30px;
}
#mobile{
    display: none;
}
@media only screen and (max-width: 768px) {
    .one__app_section{
        background-image: none;
        height: auto;
    }
    .container{
        height: auto;
    }
    .flex__content{
        display: block;
    }
    .flex__left_content{
        width: 100%;
    }
    .flex__right_content{
        display: none;
    }
    #mobile{
        display: block;
        width: 100%;
        text-align: center;
    }
}