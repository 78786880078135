.our__team_section {
    width: 100%;
    position: relative;
    margin-top: 5%;
  }
  .container {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  }
  .our__team_container {
    position: relative;
    width: calc(100%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 90%;
  }
  .our__team_container > h1 {
    width: 100%;
    font-size: 60px;
    text-align: center;
  }
  .our__team_container > p {
    margin: 0px;
    width: 80%;
    font-size: 20px;
    text-align: center;
  }
  .team__container {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .team__details {
    width: 100%;
    margin-top: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .team__content {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profile__photo {
    width: 100px;
    height: 100px;
    background-color: red;
    border-radius: 50%;
  }
  .team__content > h1 {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    margin: 0px;
  }
  .team__content > h6 {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin: 0px;
  }
  .team__content > p {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin: 0px;
  }
  
  .support__content {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .support__content > h1 {
    font-size: 60px;
  }
  .support__logos {
    width: 90%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .support__logo {
    width: 20%;
    height: 100px;
    border: 1px solid grey;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
  }
  .support__logo > img {
    width: 100%;
    object-fit: contain;
  }
  .featured__media{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
  }

  @media only screen and (max-width: 768px) {
    .container{
      height: auto;
    }
    .support__logos{
      width: 90%;
      flex-wrap: wrap;
    }
    .support__logo{
      width: 50%;
      flex-basis: 40%;
    }

  }