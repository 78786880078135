.transaction__section{
    width: 100%;
    position: relative;
    margin-top: 5%;
}
.container{
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}
.transaction__details_content{
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.transaction__title{
    width: 65%;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}
.transaction__description{
    width: 60%;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
}
.transaction__boxes{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.transacton__box{
    width: 30%;
    border: 1px solid grey;
    border-radius: 10px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
}
.transacton__box > h1 {
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0px;
}
.transacton__box > p {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0px 0px 10px 0px;
    width: 80%;
}
@media only screen and (max-width: 768px) {
    .container{
        height: auto;
    }
    .transaction__title{
        width: 100%;
        font-size: 25px;
    }
    .transaction__description{
        width: 100%;
    }
    .transaction__boxes{
        display: block;
    }
    .transacton__box{
        width: 100%;
    }
    .transaction__image{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .transaction__image > img {
        width: 100%;
        object-fit: contain;
    }
}