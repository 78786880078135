.landing__section{
    width: 100%;
    height: calc(100% - 30vh);
    position: relative;
}
.container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.landing__container{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px ;
    margin-top: 10%;
}
.landing__content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.landing__content > h1{
    font-size: 54px;
    color: var(--primary-black-text);
    text-align: center;
}
.landing__content > p{
    font-size: 20px;
    color: var(--primary-black-text);
    text-align: center;
    width: 80%;
}
.span__options{
    display: flex;
    width: 40%;
    justify-content: space-around;
    align-items: center;
}
.span__options > li {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}
.span__options > li > p {
    margin: -10px 10px 0px 10px;
}
.landing__stats{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.stats__container{
    flex: 0.2;
    height: 50px;
}
.stats__container > h3{
    margin: 0px;
    font-size: 30px;
}
.stats__container > p {
    margin: 0px;
    font-size: 15px;
}
@media only screen and (max-width: 768px) {

    .landing__section{
        height: auto;
    }
    .landing__container{
        height: auto;
    }
    .landing__content > p{
        width: 100%;
    }
    .span__options{
        width: 100%;
    }
}